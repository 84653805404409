$phone-max-width: 767px;
$non-phone-min-width: $phone-max-width + 1;
$only-non-phone-devices: "only screen and (min-width: #{$non-phone-min-width})";
$only-small-screens: "only screen and (max-width: #{$phone-max-width})";

h1.titleVariantSecondary {
  color: var(--primary-gray-color);
  font-size: 0.75rem;
  text-transform: uppercase;
}

.logo {
  @media #{$only-non-phone-devices} {
    max-height: 150px;
  }

  @media #{$only-small-screens} {
    max-height: 150px;
    max-width: 250px;
  }
}
