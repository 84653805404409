$black: #000;
$faded-blue: #d9e2f3;
$grey: #e3e3e3;

.privacyStatement {
  .heading1 {
    text-align: center;
    text-decoration: underline;
  }

  .heading2 {
    background-color: $faded-blue;
    border: 1px solid $black;
    color: $black;
    margin: 20px 0;
    text-align: center;
  }

  .heading3 {
    background-color: $grey;
    border: 1px solid $black;
    color: $black;
    margin: 20px;
    text-align: center;
  }

  .heading4 {
    font-size: 1.1em;
    font-weight: 900;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .emphatic {
    font-weight: 900;
  }

  .inlineHeader {
    font-weight: 900;
    text-decoration: underline;
  }

  .underlineItalic {
    font-style: italic;
    text-decoration: underline;
  }

  .sectionHeader {
    margin: 20px 0;
  }

  .standardTable {
    border: 1px solid $black;
    border-collapse: collapse;
    margin-bottom: 20px;

    th {
      background-color: $grey;
    }

    th,
    td {
      border: 1px solid $black;
      padding: 5px;
      text-align: left;
    }
  }

  .list {
    padding-bottom: 15px;
    padding-left: 15px;
  }

  .listItem {
    list-style-type: disc;
  }

  h2 {
    font-size: 1.1em;
    margin-bottom: 1em;
    text-align: center;
  }

  ul {
    padding-bottom: 15px;
    padding-left: 15px;
  }

  button {
    font-weight: 900;
  }
}
