$black: #000;

.border {
  border: 1px solid $black;
  padding: 1em;
}

h2 {
  font-size: 1.1em;
}
