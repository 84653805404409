$backdrop-color: rgba(0, 0, 0, 0.7);
$white: #fff;
$modal-z-index: 1300;

.backdrop {
  background: $backdrop-color;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $modal-z-index - 1;
}

.modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 4em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: $modal-z-index;

  &::before {
    display: none;
  }
}

.wide {
  padding: 0.5em;
}

.shrink {
  align-items: center;
  height: auto;
}

.contained {
  position: relative;
}

.container {
  background: $white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
  position: relative;
}

.containerShrink {
  height: auto;
  width: auto;
}

.header {
  flex: 0 0 auto;
  padding: 1em 1em 0 1em;
  text-align: center;

  .closeButton {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.content {
  flex: 1;
  overflow: auto;
  padding: 1em;
  word-wrap: break-word;
}

.contentWithoutGutters {
  padding: 1em 0;
}

.modalCarouselSlideRightEnter,
.modalCarouselSlideLeftLeave {
  transform: translateX(100%);
}

.modalCarouselSlideRightLeave,
.modalCarouselSlideLeftEnter {
  transform: translateX(-100%);
}

.modalCarouselSlideRightEnterActive,
.modalCarouselSlideLeftEnterActive {
  transform: translateX(0);
}

.modalCarouselSlideRightEnterActive,
.modalCarouselSlideRightLeave,
.modalCarouselSlideLeftEnterActive,
.modalCarouselSlideLeftLeave {
  transition: all 1.5s ease;
}
