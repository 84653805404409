.root {
  background-color: inherit;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  box-sizing: border-box;

  .logoImage {
    max-height: 48px;
    max-width: 150px;
  }
}
