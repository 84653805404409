.iframeContainer {
  display: table;
  empty-cells: show;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

.iframeContainerRow {
  display: table-row;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  display: block;
}

.blockLink {
  display: block;
}
